import * as Sentry from "@sentry/react";

export const sentryBrowserConfig = () => {
  // eslint-disable-next-line
  import.meta.env.PROD === true &&
    Sentry.init({
      dsn: "https://a419f8f8ef0d6f4ba5e1342300fe8202@o422027.ingest.us.sentry.io/4508236927205376",
      environment: "production",
      enabled: import.meta.env.DEV ? false : true,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.globalHandlersIntegration({ onerror: true, onunhandledrejection: true }),
      ],
      autoSessionTracking: globalThis?.window?.document ? true : false, // disable autoSessionTracking in SSR
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 1.0,
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [/^\//, /^https:\/\/api\.dreamvault\.dev/],
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
};
